// extracted by mini-css-extract-plugin
export var image = "verticalImageReel-module--image--e5faf";
export var imageContainer = "verticalImageReel-module--image-container--9c0d2";
export var paused = "verticalImageReel-module--paused--b9773";
export var slideDown1 = "verticalImageReel-module--slide-down-1--b2bc1";
export var slideDown10 = "verticalImageReel-module--slide-down-10--f929b";
export var slideDown11 = "verticalImageReel-module--slide-down-11--5e922";
export var slideDown12 = "verticalImageReel-module--slide-down-12--843b2";
export var slideDown13 = "verticalImageReel-module--slide-down-13--b9a09";
export var slideDown14 = "verticalImageReel-module--slide-down-14--7c525";
export var slideDown15 = "verticalImageReel-module--slide-down-15--d8f70";
export var slideDown16 = "verticalImageReel-module--slide-down-16--e73ce";
export var slideDown17 = "verticalImageReel-module--slide-down-17--9dae9";
export var slideDown18 = "verticalImageReel-module--slide-down-18--f3dc9";
export var slideDown19 = "verticalImageReel-module--slide-down-19--9e620";
export var slideDown2 = "verticalImageReel-module--slide-down-2--b8d43";
export var slideDown20 = "verticalImageReel-module--slide-down-20--6b9aa";
export var slideDown3 = "verticalImageReel-module--slide-down-3--d3e74";
export var slideDown4 = "verticalImageReel-module--slide-down-4--df735";
export var slideDown5 = "verticalImageReel-module--slide-down-5--b9bbc";
export var slideDown6 = "verticalImageReel-module--slide-down-6--b7e01";
export var slideDown7 = "verticalImageReel-module--slide-down-7--77d53";
export var slideDown8 = "verticalImageReel-module--slide-down-8--e573c";
export var slideDown9 = "verticalImageReel-module--slide-down-9--d2fb5";
export var slideUp1 = "verticalImageReel-module--slide-up-1--cc46a";
export var slideUp10 = "verticalImageReel-module--slide-up-10--21950";
export var slideUp11 = "verticalImageReel-module--slide-up-11--81fde";
export var slideUp12 = "verticalImageReel-module--slide-up-12--e7804";
export var slideUp13 = "verticalImageReel-module--slide-up-13--c22d0";
export var slideUp14 = "verticalImageReel-module--slide-up-14--dbddc";
export var slideUp15 = "verticalImageReel-module--slide-up-15--f97f8";
export var slideUp16 = "verticalImageReel-module--slide-up-16--97b28";
export var slideUp17 = "verticalImageReel-module--slide-up-17--fdc0e";
export var slideUp18 = "verticalImageReel-module--slide-up-18--6fe42";
export var slideUp19 = "verticalImageReel-module--slide-up-19--c3955";
export var slideUp2 = "verticalImageReel-module--slide-up-2--0dc2d";
export var slideUp20 = "verticalImageReel-module--slide-up-20--b04d1";
export var slideUp3 = "verticalImageReel-module--slide-up-3--d7ab5";
export var slideUp4 = "verticalImageReel-module--slide-up-4--a63cb";
export var slideUp5 = "verticalImageReel-module--slide-up-5--7068e";
export var slideUp6 = "verticalImageReel-module--slide-up-6--d57d4";
export var slideUp7 = "verticalImageReel-module--slide-up-7--90fc9";
export var slideUp8 = "verticalImageReel-module--slide-up-8--cfc68";
export var slideUp9 = "verticalImageReel-module--slide-up-9--2d7d0";
export var verticalImageReel = "verticalImageReel-module--vertical-image-reel--30b92";